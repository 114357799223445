import React from "react";
import PropTypes from "prop-types";
import { Divider, Box, Button } from "@mui/material";
import { useFormik } from "formik";
import { fieldNames, validationSchema } from "./formConfig";
import { BLACK } from "../../../../../common/constants/colors";
import InputPasswordField from "../../../../../common/components/InputPasswordField";

const Form = ({ onSubmit, setIsOpen, isNewModalOpen }) => {
  const initialValues = {
    [fieldNames.CURRENT_PASSWORD]: "",
    [fieldNames.NEW_PASSWORD]: "",
    [fieldNames.CONFIRM_PASSWORD]: "",
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleBlur,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const inputStyles = {
    fontSize: "14px",
    fontWeight: 600,
  };

  return (
    <>
      <Box
        sx={{
          padding: "0px 10px 0px 10px",
          boxSizing: "border-box",
        }}
      >
        <>
          <InputPasswordField
            sx={inputStyles}
            fullWidth
            containerSx={{ marginBottom: "16px" }}
            id="currentPassword"
            placeholder="Current Password"
            label="Current Password*"
            variant="filled"
            error={
              errors[fieldNames.CURRENT_PASSWORD] &&
              touched[fieldNames.CURRENT_PASSWORD] && {
                id: errors[fieldNames.CURRENT_PASSWORD],
              }
            }
            name={fieldNames.CURRENT_PASSWORD}
            value={values[fieldNames.CURRENT_PASSWORD]}
            handleChange={handleChange}
            onBlur={handleBlur}
          />
          <InputPasswordField
            sx={inputStyles}
            fullWidth
            containerSx={{ marginBottom: "16px" }}
            id="newPassword"
            placeholder="New Password"
            label="New Password*"
            variant="filled"
            error={
              errors[fieldNames.NEW_PASSWORD] &&
              touched[fieldNames.NEW_PASSWORD] && {
                id: errors[fieldNames.NEW_PASSWORD],
              }
            }
            name={fieldNames.NEW_PASSWORD}
            value={values[fieldNames.NEW_PASSWORD]}
            handleChange={handleChange}
            onBlur={handleBlur}
          />
          <InputPasswordField
            fullWidth
            sx={inputStyles}
            containerSx={{ marginBottom: "16px" }}
            id="confirmPassword"
            placeholder="Confirm Password"
            label="Confirm Password*"
            variant="filled"
            error={
              errors[fieldNames.CONFIRM_PASSWORD] &&
              touched[fieldNames.CONFIRM_PASSWORD] && {
                id: errors[fieldNames.CONFIRM_PASSWORD],
              }
            }
            name={fieldNames.CONFIRM_PASSWORD}
            value={values[fieldNames.CONFIRM_PASSWORD]}
            handleChange={handleChange}
            onBlur={handleBlur}
          />
        </>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
          padding: "16px",
          boxSizing: "border-box",
        }}
      >
        <Button
          sx={{
            padding: "6px 20px",
            textTransform: "none",
            marginRight: isNewModalOpen?.isNeedPasswordReset ? "0px" : "10px",
          }}
          variant="contained"
          disabled={
            !isValid ||
            !values[fieldNames.CURRENT_PASSWORD] ||
            !values[fieldNames.NEW_PASSWORD] ||
            !values[fieldNames.CONFIRM_PASSWORD]
          }
          onClick={handleSubmit}
        >
          Save Password
        </Button>
        {!isNewModalOpen?.isNeedPasswordReset && (
          <Button
            sx={{
              padding: "6px 20px",
              textTransform: "none",
              color: BLACK,
              borderColor: BLACK,
            }}
            variant="outlined"
            onClick={() => {
              setIsOpen({
                isOpen: false,
                isNeedPasswordReset: false,
              });
            }}
          >
            Cancel
          </Button>
        )}
      </Box>
    </>
  );
};

Form.defaultProps = {
  isRememberMeChecked: false,
  setIsRememberMeChecked: () => {},
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setIsOpen: PropTypes.object.isRequired,
  isNewModalOpen: PropTypes.object.isRequired,
  isRememberMeChecked: PropTypes.bool.isRequired,
  setIsRememberMeChecked: PropTypes.func.isRequired,
};

export default Form;
