import React from 'react';
import Spinner from "../Spinner/Spinner";

const RouteLoader = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spinner color="primary" />
  </div>
);

export default RouteLoader;
