const Production = require('./production');
const Development = require('./development');
const Uat = require('./uat');

const config = {
  production: Production,
  development: Development,
  uat: Uat,
};
// get app environment
const env = process.env.REACT_APP_BUILD_ENV || 'production';
const configFile = config[env] || config.development;

// export config file
module.exports = configFile;