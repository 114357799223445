import React, { Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { isTokenAvailable } from "../../../libs/auth";
import "../../../networkInterceptor";
import Layout from "../Layout/Layout";
import { adminRoutes, routes } from "../../../routes/routes";
import RouteLoader from "../RouteLoader/RouteLoader";
import endpoints from "../../../routes/endpoints";

const ProtectedRoutes = () => {
  const userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const isSuperAdmin = userData?.userType === 0;
  const WithAuth = ({ component: Component }) => {
    if (!isTokenAvailable()) {
      return <Navigate to={endpoints.login} />;
    }
    return <Component />;
  };
  return (
    <Layout>
      <Suspense fallback={<RouteLoader />}>
        <Routes>
          {(isSuperAdmin ? adminRoutes : routes).map(
            ({ component: Component, path, exact }) => {
              return (
                <Route
                  path={path}
                  key={path}
                  exact={exact}
                  element={<WithAuth component={Component} path={path} />}
                />
              );
            }
          )}
          <Route
            path="*"
            element={
              <WithAuth
                component={lazy(() => import("../../../views/PageNotFound"))}
              />
            }
          />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default ProtectedRoutes;
