import PropTypes from "prop-types";
import React from "react";
import { CircularProgress, Box } from "@mui/material";

const Spinner = ({ color }) => (
  <Box sx={{ display: "flex" }}>
    <CircularProgress color={color} />
  </Box>
);

Spinner.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Spinner;
