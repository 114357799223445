export const BUTTON_BLUE_COLOR = '#386BF6';
export const BACKGROUND_LAYOUT_GRAY = '#f5f5f5';
export const WHITE = '#FFFFFF';
export const BLACK = '#000';
export const AVATAR_BACKGROUND = '#D7E9FF';
export const AVATAR_TEXT = '#386BF6';
export const ACTION_BUTTON_GREEN = '#198754';
export const ACTION_BUTTON_RED = '#dc3545';
export const PRIMARY = '#0D6EFD';
export const BORDER_COLOR = '#ddd';
export const TEXT_FIELD_RED = '#F8D7DA';
export const TABLE_HEAD_BACKGROUND_COLOR = '#FBFBFB';
export const ACTION_BUTTON_GRAY = '#4A4A4A';
export const ACTION_BUTTON_HOVER_GRAY = '#6C757D';
export const PRIMARY_2 = '#EBF0FF';
export const DROPDOWN_HOVER = '#F8F9FA';