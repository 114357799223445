import { createContext, useReducer } from "react";
import * as types from "./types";

export const initialState = {
  isError: false,
  message: "",
  isOpen: true,
};

const notificationReducer = (state, action) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return {
        ...state,
        isError: action.payload.isError,
        isOpen: true,
        message: action.payload.message,
      };
    case types.CLEAR_NOTIFICATION:
      return {
        ...state,
        isError: false,
        message: "",
        isOpen: false,
      };
    default:
      return state;
  }
};

export const NotificationContext = createContext(initialState);

const NotificationProvider = ({ children }) => {
  const [notificationState, notificationDispatch] = useReducer(
    notificationReducer,
    initialState
  );

  return (
    <NotificationContext.Provider
      value={[notificationState, notificationDispatch]}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
