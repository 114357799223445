/* eslint-disable react/display-name */
import userProvider from "./user/userProvider";
import notificationProvider from "./notification/notificationProvider";

// Import other created Providers and add them here -
const providers = [
  userProvider,
  notificationProvider,
];

const combineProviders = (components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};

// Combining multiple providers to single provider - this will be wrapped around App.js
export default combineProviders(providers);
