import * as Yup from "yup";
import { passwordRegex } from "../../../../../helpers/validators";

export const fieldNames = {
  CURRENT_PASSWORD: "currentPassword",
  NEW_PASSWORD: "newPassword",
  CONFIRM_PASSWORD: "confirmPassword",
};

export const validationSchema = Yup.object().shape({
  [fieldNames.CURRENT_PASSWORD]: Yup.string()
    .required("Password required"),
  [fieldNames.NEW_PASSWORD]: Yup.string()
    .required("Password required")
    .matches(passwordRegex, "Passwords must consist of at least 8 alphanumeric characters, including a minimum of 1 uppercase letter and 1 special character.")
    .required("New Password required"),
  [fieldNames.CONFIRM_PASSWORD]: Yup.string()
    .matches(passwordRegex, "Passwords must consist of at least 8 alphanumeric characters, including a minimum of 1 uppercase letter and 1 special character.")
    .required("Confirm Password required")
    .oneOf([Yup.ref(fieldNames.NEW_PASSWORD), null], "Password does not match"),
});
