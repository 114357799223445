import httpService from './httpService'
import SERVICE_URL from '../constants/serviceUrls'

const { ACCOUNT } = SERVICE_URL;
// signIn API 
export const signInApi = async (details) =>{
  return httpService.post({
    url: `${ACCOUNT}login`,
    data: details,
    isCacheEnabled: false,
  });
}

export const logoutApi = async (details) =>{
  return httpService.post({
    url: `${ACCOUNT}logout?UserId=${details?.userId}`,
    isCacheEnabled: false,
  });
}

export const forgotPasswordApi = async (details) =>{
  return httpService.post({
    url: `${ACCOUNT}forgot`,
    data: details,
    isCacheEnabled: false,
  });
}

export const verifyOtpApi = async (details) =>{
  return httpService.post({
    url: `${ACCOUNT}verify-otp`,
    data: details,
    isCacheEnabled: false,
  });
}

export const resendOtpApi = async (details) =>{
  return httpService.post({
    url: `${ACCOUNT}resend-otp`,
    data: details,
    isCacheEnabled: false,
  });
}

export const changePasswordApi = async (details) =>{
  return httpService.post({
    url: `${ACCOUNT}change-password`,
    data: details,
    isCacheEnabled: false,
  });
}

export const resetPasswordApi = async (details) =>{
  return httpService.post({
    url: `${ACCOUNT}reset-password`,
    data: details,
    isCacheEnabled: false,
  });
}

