const SERVICE_URL = {
  ACCOUNT: `/api/v1.0/account/`,
  ADMIN: `/api/v1.0/manage/admins`,
  MANAGE_USER: `/api/v1.0/manage/users`,
  ADVERTISEMENT: `/api/v1.0/manage/advertisements`,
  USER: `/api/v1.0/users`,
  USER_BOX: `/api/v1.0/boxes`,
  CONTAINER: `/api/v1.0/containers`,
  LOCATION: `/api/v1.0/locations`,
  ITEM: `/api/v1.0/items`,
  IMAGE: `/api/v1.0/images`,
  SETTINGS: `/api/v1.0/manage/app-setting`,
};

export default SERVICE_URL;
