import { createContext, useReducer } from "react";
import * as types from "./types";

const initialState = {
  selectedTab: 1,
};

const userReducer = (state, action) => {
  switch (action.type) {
    case types.SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    default:
      return state;
  }
};

export const UserContext = createContext(initialState);

const UserProvider = ({ children }) => {
  const [userState, userDispatch] = useReducer(
    userReducer,
    initialState
  );

  return (
    <UserContext.Provider value={[userState, userDispatch]}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
